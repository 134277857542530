import React from "react"
import { Link } from "gatsby"
import logo_white from "../images/logo_white.png"

const Header = ({ siteTitle }) => (
  <>
    {/* <div
      className="alert alert-primary"
      style={{
        position: "fixed",
        top: 0,
        zIndex: 50,
        width: "100vw",
        height: 55,
      }}
      role="alert"
    >
      <p
        style={{ color: "#000", fontSize: 15 }}
        className="text-center text-uppercase font-weight-bold"
      >
        Now accepting deposits for summer 2020
      </p>
    </div> */}
    <nav
      className="navbar navbar-expand-lg navbar navbar-light "
      style={{
        backgroundColor: "#cab683",
        paddingBottom: 0,
        /*marginTop: 50,*/
      }}
    >
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <img src={logo_white} height="80" alt="" loading="lazy" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span
            className="navbar-toggler-icon"
            style={{ color: "#495057" }}
          ></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link
                to="/"
                className="nav-link"
                activeStyle={{ color: "#fff", fontWeight: "bold" }}
                style={{ color: "#495057" }}
              >
                Home <span className="sr-only">(current)</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/puppies"
                className="nav-link"
                activeStyle={{ color: "#fff", fontWeight: "bold" }}
                style={{ color: "#495057" }}
              >
                Reserve
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/parents"
                className="nav-link"
                activeStyle={{ color: "#fff", fontWeight: "bold" }}
                style={{ color: "#495057" }}
              >
                Parents
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/about"
                className="nav-link"
                activeStyle={{ color: "#fff", fontWeight: "bold" }}
                style={{ color: "#495057" }}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contact"
                className="nav-link"
                activeStyle={{ color: "#fff", fontWeight: "bold" }}
                style={{ color: "#495057" }}
              >
                Contact
              </Link>
            </li>
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                style={{ color: "#495057" }}
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Gallery
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="#">
                  Mom
                </a>
                <a className="dropdown-item" href="#">
                  Dad
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">
                  Puppies
                </a>
              </div>
            </li> */}
            <div className="pl-3">
              <a
                href="https://www.instagram.com/sunnydoodlesaz/"
                target="_blank"
              >
                <i className="fab fa-instagram fa-2x mr-3"></i>
              </a>
              <a
                href="https://www.facebook.com/Sunny-Doodles-AZ-103588868076483"
                target="_blank"
              >
                <i className="fab fa-facebook-square fa-2x"></i>
              </a>
              <a
                href="https://www.youtube.com/channel/UCOgVMqi67-hxRmpDF66eMEA"
                target="_blank"
                className="pl-3"
              >
                <i className="fab fa-youtube fa-2x"></i>
              </a>
            </div>
          </ul>
        </div>
      </div>
    </nav>
  </>
)

export default Header
